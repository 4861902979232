import axios from "../../axios-config";

// 商品分类维护-查询商品类型

export function getCommodityList(params: any) {
  return axios.get("/api/back/mall/CategoryController/find", {params:{
    ...params
  }});
}
// 商品分类维护-新增商品类型
export function addCommodity(data: any) {
  return axios.post("/api/back/mall/CategoryController", data);
}

// 商品分类维护-修改商品类型
export function updateCommodity(data: any) {
  return axios.put("/api/back/mall/CategoryController", data);
}


// 商品分类维护-删除商品类型
export function deleteCommodity(params: any) {
  return axios.delete("/api/back/mall/CategoryController", {params:{
    ...params
  }});
}